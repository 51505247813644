/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// 1.3 Commit test

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"

import Header from "./template-partials/hcp/ppl/header"
import ScrollToISIDiv from "./scrolltoisi"
import "./layout.scss"

import IsiHcpPpl from "./template-partials/global-components/isi/isi-hcp-ppl.js"
import GlobalFooter from "./template-partials/global-components/global-footer.js"
import CookieBanner from "./template-partials/global-components/cookie-banner"
import Interstitial from "./template-partials/global-components/interstitial.js"

import { Waypoint } from "react-waypoint"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

import HcpBBsSd from "./template-partials/hcp/bbs/hcp-bbs-sd"
import HcpPplSd from "./template-partials/hcp/ppl/hcp-ppl-sd"

const Layout = ({ children, pageId, noNav, ppl }) => {
  const [screenShot, setScreenShot] = useState(false)

  const handleWaypointEnter = () => {
    document.getElementById("isi-docked-container").style.display = "none"
    if (document.getElementById("cookie-banner")) {
      document.getElementById("cookie-banner").classList.add("drop")
    }
    document.querySelector(".main-nav").style.setProperty("--isi-height", "0")
  }
  const handleWaypointLeave = () => {
    document.getElementById("isi-docked-container").style.display = "block"
    if (document.getElementById("cookie-banner")) {
      document.getElementById("cookie-banner").classList.remove("drop")
    }
    document
      .querySelector(".main-nav")
      .style.setProperty("--isi-height", "200px")
  }
  const openCloseIsi = e => {
    let isiDock = document.getElementById("isi-docked-container")
    let bodyTag = document.querySelector("body")

    if (!isiDock.classList.contains("isi-expanded")) {
      isiDock.classList.add("isi-expanded")
      bodyTag.classList.add("isi-body-expanded")
      document.getElementById("isi-button-toggle-copy").innerHTML = "COLLAPSE "
      document.getElementById("isi-font-arrow-up").style.display = "none"
      document.getElementById("isi-font-arrow-down").style.display = "inline"
      if (document.getElementById("cookie-banner")) {
        document.getElementById("cookie-banner").classList.add("drop")
      }
    } else {
      isiDock.classList.remove("isi-expanded")
      bodyTag.classList.remove("isi-body-expanded")
      document.getElementById("isi-button-toggle-copy").innerHTML = "EXPAND "
      document.getElementById("isi-font-arrow-up").style.display = "inline"
      document.getElementById("isi-font-arrow-down").style.display = "none"
      if (document.getElementById("cookie-banner")) {
        document.getElementById("cookie-banner").classList.remove("drop")
      }
    }
  }

  const checkScroll = () => {
    let scrollTop =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop
    return scrollTop
  }

  useEffect(() => {
    let mh = document.querySelector(".main-header")
    window.onscroll = function () {
      if (checkScroll() > 100) {
        mh.classList.add("main-header--scrolled")
      } else {
        if (mh.classList.contains("main-header--scrolled")) {
          mh.classList.remove("main-header--scrolled")
        }
      }
    }

    let urlParams = new URLSearchParams(window.location.search)
    let screenshotParam = urlParams.get("screenshot")

    if (screenshotParam && screenshotParam.toLowerCase() === "true") {
      // Set the variable to true
      setScreenShot(true)
    } else {
      // Set the variable to false
      return false
    }
  })

  return (
    <>
      <Header noNav={noNav} ppl={ppl} />
      <ScrollToISIDiv />
      <div className={noNav ? "container container--no-nav" : "container"} id="isiContainer" >
        <div className="row">
          <div className="columns">
            <main>{children}</main>

            <Waypoint
              onEnter={handleWaypointEnter}
              onLeave={handleWaypointLeave}
              bottomOffset="100px"
            >
              <div className="lg_mt-2 mt-2">
                <div className="row">
                  
                  <div className="columns og small-12">
                    <IsiHcpPpl  />
                  </div>
                </div>
              </div>
            </Waypoint>
          </div>
        </div>

        <div id="isi-docked-container" className={screenShot ? `sshide` : ``}>
          {!screenShot && (
            <>
              <div id="isi-expand-collapse-bar" className="color-gray-bg">
                <div className="row row align-middle og">
                  <div className="columns og color-white">
                    <strong>Important Safety Information</strong>
                  </div>
                  <div className="columns og shrink text-right">
                    <button
                      id="isi-button-toggle"
                      onClick={openCloseIsi}
                      style={{ fontWeight: "bold" }}
                    >
                      <span id="isi-button-toggle-copy" className="color-white">
                        EXPAND{" "}
                      </span>
                      <span className="color-neon-green">
                        <span id="isi-font-arrow-up">
                          <FontAwesomeIcon icon={faAngleUp} />
                        </span>
                        <span id="isi-font-arrow-down">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row og lg_mt-1 mt-1">
                <div className="columns og">
                  <IsiHcpPpl />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <GlobalFooter type="hcp-ppl" code={"US-SET-2200002 - (07.01/2025)"} />
      {!screenShot && <CookieBanner />}
      <HcpPplSd />
      <Interstitial />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
